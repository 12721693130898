var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.msgOccurs)?_c('v-navigation-drawer',{ref:"messageDisplay",staticClass:"chat-menu",attrs:{"absolute":"","temporary":"","right":"","width":"674"},model:{value:(_vm.showChat),callback:function ($$v) {_vm.showChat=$$v},expression:"showChat"}},[_c('v-dialog',{attrs:{"hide-overlay":"","attach":true,"max-width":"600px"},model:{value:(_vm.imageSlider),callback:function ($$v) {_vm.imageSlider=$$v},expression:"imageSlider"}},[_c('v-carousel',{staticClass:"carousel-wrapper",attrs:{"show-arrows":false,"hide-delimiters":""}},[_c('span',{staticClass:"carousel-close-icon",on:{"click":function($event){_vm.imageSlider = false;
        _vm.imgUrl = null;}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-carousel-item',[_c('img',{attrs:{"src":_vm.imgUrl,"width":"100%","height":"100%","alt":_vm.imgUrl,"eager":""}})])],1)],1),(!_vm.openChat)?_c('div',{staticClass:"modal-fullscreen chatmodal-dialog"},[_c('div',{staticClass:"modal-header pattern-green pattern-g-after"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"chatModalLabel"}},[_c('img',{staticClass:"chat-icon-m",attrs:{"src":require("../../assets/images/chat-icon1.svg")}})]),_c('v-toolbar-title',{staticClass:"white--text ml-3"},[_vm._v(_vm._s(_vm.$t("chat.chat"))+" ")]),_c('v-spacer'),_c('div',{staticClass:"search-wrap"},[_c('v-text-field',{staticClass:"font14",attrs:{"hide-details":"auto","elevation":"0","height":"40","label":_vm.$t('chat.search'),"dense":"","solo":""},on:{"input":_vm.filterMember},model:{value:(_vm.searchMember),callback:function ($$v) {_vm.searchMember=$$v},expression:"searchMember"}}),_c('div',{staticClass:"d-flex"},[_c('img',{attrs:{"src":require("../../assets/images/lucide_search.svg"),"alt":""}})])],1),_c('div',{staticClass:"cursorPointer ml-3",attrs:{"color":"white"},on:{"click":function($event){_vm.showChat = false}}},[_c('img',{attrs:{"src":require("../../assets/images/close.svg")}})])],1),_c('div',{staticClass:"chat-list"},[_c('table',{staticClass:"table chat1-screen"},_vm._l((_vm.allUsers),function(item,index){return _c('tr',{key:index,staticClass:"friend-item",on:{"click":function($event){_vm.chat = [];
          _vm.currentUser = item;
          _vm.openChat = true;
          _vm.openSendMessage(item.id, item.representative_name);}}},[_c('td',[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"user-avtar"},[_c('v-avatar',{staticClass:"user-avtar-icon rounded-circle",attrs:{"size":"40"}},[_c('span',[_vm._v(_vm._s(item.company_name.split(' ').slice(0, 2) .map(word => word.charAt(0).toUpperCase()) .join('')))])]),_c('v-avatar',{staticClass:"status-icon",attrs:{"color":item.online ? '#1FD445' : '#FF000C',"size":"11"}})],1),_c('div',{staticClass:"user-dtl ml-4"},[_c('h4',{class:item.company_name === 'Admin User'
                      ? 'fw-medium content g-color chatRepNameAdmin'
                      : 'fw-medium content g-color chatRepName'},[_vm._v(_vm._s(item.company_name))]),_c('p',{staticClass:"heading text-dark"},[_vm._v(" "+_vm._s(item.representative_name)+" ")])])])]),_c('td',[(item.notSeen)?_c('v-avatar',{staticClass:"ml-4 showUserNotiCount",attrs:{"color":'#A0DCA0',"size":"26"}},[_vm._v(" "+_vm._s(item.notSeen))]):_vm._e()],1)])}),0)])]):_vm._e(),(_vm.openChat)?_c('div',{staticClass:"modal-fullscreen chatmodal-dialog inr-modal"},[_c('div',{staticClass:"modal-header pattern-green pattern-g-after"},[_c('div',{staticClass:"d-flex align-center",on:{"click":function($event){_vm.openChat = false; _vm.dropChatScreen(); _vm.msg = ''; _vm.image = ''; _vm.unreadedMessages = true;}}},[_c('img',{staticClass:"chat-icon-m",attrs:{"src":require("../../assets/images/arrow-left.svg")}}),_c('v-toolbar-title',{staticClass:"white--text ml-3"},[_vm._v(_vm._s(_vm.currentUser.representative_name)+" ")])],1),(_vm.typing)?_c('span',{staticClass:"body-1 typing"},[_c('i',[_vm._v(_vm._s(_vm.$t("chat.typing"))+"...")])]):_vm._e()]),_c('div',{staticClass:"chat-container"},[_c('div',{staticClass:"chat-body"},_vm._l((_vm.chat),function(item,index){return _c('div',{key:index,class:[
            'd-flex flex-row align-center',
            item.from == _vm.currentUserData[0].id ? 'justify-end' : null,
          ]},[(item.from == _vm.currentUserData[0].id)?_c('span',{staticClass:"ml-3 ml-sm-6 mb-3 chatting-msg own-chatting"},[_c('h6',{staticClass:"chatDate"},[_vm._v(" "+_vm._s(_vm._f("DateTimezoneFilter")(item.sent))+" ")]),_c('span',{staticClass:"chattext own-chattext"},[(item.image !== '')?_c('img',{staticClass:"cursorPointer",staticStyle:{"max-width":"330px"},attrs:{"src":item.image,"height":"140px"},on:{"click":function($event){_vm.imageSlider = true;
                  _vm.imgUrl = item.image;}}}):_vm._e(),(item.msg !== '')?_c('div',[_vm._v(_vm._s(item.msg))]):_vm._e()]),_c('v-avatar',{staticClass:"on-user float-right",attrs:{"size":"24"}},[_c('span',[_vm._v(" "+_vm._s(_vm.currentUserData[0].representative_name[0])+" ")])])],1):_vm._e(),(item.from == _vm.currentUser.id)?_c('div',[(_vm.unreadedMessages && item.unseen)?_c('div',{staticClass:"separator"},[_vm._v(" "+_vm._s(_vm.$t("chat.unreadMessages"))+" ")]):_vm._e(),_c('div',{staticClass:"d-flex chatting-msg"},[_c('div',{staticClass:"user-avtar"},[_c('v-avatar',{staticClass:"user-avtar-icon rounded-circle",attrs:{"size":"40"}},[_c('span',[_vm._v(_vm._s(_vm.currentUser.representative_name.split(' ').slice(0, 2) .map(word => word.charAt(0).toUpperCase()) .join('')))])]),_c('v-avatar',{staticClass:"status-icon",attrs:{"color":item.online ? '#1FD445' : '#FF000C',"size":"11"}})],1),_c('div',{staticClass:"ml-2 ml-sm-4 mr-4 mr-sm-5"},[_c('h6',[_vm._v(_vm._s(_vm._f("DateTimezoneFilter")(item.sent)))]),_c('span',{staticClass:"chattext"},[(item.image !== '')?_c('img',{staticClass:"cursorPointer",staticStyle:{"max-width":"330px"},attrs:{"src":item.image,"height":"140px"},on:{"click":function($event){_vm.imageSlider = true;
                      _vm.imgUrl = item.image;}}}):_vm._e(),(item.msg !== '')?_c('div',[_vm._v(_vm._s(item.msg))]):_vm._e()])])])]):_vm._e()])}),0),_c('div',{staticClass:"chat-footer d-flex justify-space-between"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.msg),expression:"msg"}],staticClass:"form-control",attrs:{"type":"text","placeholder":_vm.$t('chat.type_message'),"id":"chatInput"},domProps:{"value":(_vm.msg)},on:{"click":function($event){_vm.unreadedMessages = false},"keyup":function($event){return _vm.userTyping(_vm.currentUser)},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.send(_vm.currentUser)},"input":function($event){if($event.target.composing)return;_vm.msg=$event.target.value}}}),_c('button',{staticClass:"btn custom-btn pattern-btn send-btn",attrs:{"id":"sendBtn","loading":_vm.startUploadImage},on:{"click":function($event){return _vm.send(_vm.currentUser)}}},[_c('span',[_c('img',{staticClass:"chat-icon-m",attrs:{"src":require("../../assets/images/send.svg")}})])])])])]):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }