import moment from "moment";
import { DATE_FORMAT } from "@/constants/common";

const getEndDate = (date) => {
  return moment(date).utc().endOf("month").format(DATE_FORMAT.YYYY_MM_DD);
};

const getStartDate = (date) => {
  return moment(date).utc().startOf("month").format(DATE_FORMAT.YYYY_MM_DD);
};

const getOneMonthBackDate = () => {
  return moment().utc().subtract(1, "month").format(DATE_FORMAT.YYYY_MM_DD);
};

const getCurrentDate = () => {
  return moment().utc().format(DATE_FORMAT.YYYY_MM_DD);
};

export default {
  getEndDate,
  getStartDate,
  getOneMonthBackDate,
  getCurrentDate,
};
