<template>
  <div>
    <v-navigation-drawer v-model="showChat" v-if="msgOccurs" absolute temporary right width="674" class="chat-menu"
      ref="messageDisplay">
      <v-dialog hide-overlay :attach="true" v-model="imageSlider" max-width="600px">
        <v-carousel :show-arrows="false" hide-delimiters class="carousel-wrapper">
          <span class="carousel-close-icon" @click="
            imageSlider = false;
          imgUrl = null;
          "><v-icon>mdi-close</v-icon></span>
          <v-carousel-item>
            <img :src="imgUrl" width="100%" height="100%" :alt="imgUrl" eager />
          </v-carousel-item>
        </v-carousel>
      </v-dialog>
      <div v-if="!openChat" class="modal-fullscreen chatmodal-dialog">
        <div class="modal-header pattern-green pattern-g-after">
          <h5 class="modal-title" id="chatModalLabel">
            <img class="chat-icon-m" src="../../assets/images/chat-icon1.svg" />
          </h5>
          <v-toolbar-title class="white--text ml-3">{{ $t("chat.chat") }}
          </v-toolbar-title>
          <v-spacer />
          <div class="search-wrap">
            <v-text-field v-model="searchMember" hide-details="auto" elevation="0" height="40" :label="$t('chat.search')"
              @input="filterMember" dense solo class="font14"></v-text-field>
            <div class="d-flex">
              <img src="../../assets/images/lucide_search.svg" alt="">
            </div>
          </div>
          <div @click="showChat = false" class="cursorPointer ml-3" color="white">
            <img src="../../assets/images/close.svg" />
          </div>
        </div>
        <div class="chat-list">
          <table class="table chat1-screen">
            <tr v-for="(item, index) in allUsers" :key="index" class="friend-item" @click="
              chat = [];
            currentUser = item;
            openChat = true;
            openSendMessage(item.id, item.representative_name);
            ">
              <td>
                <div class="d-flex align-center">
                  <div class="user-avtar">
                    <v-avatar size="40" class="user-avtar-icon rounded-circle">
                      <span>{{ item.company_name.split(' ').slice(0, 2)
                        .map(word => word.charAt(0).toUpperCase())
                        .join('') }}</span>
                    </v-avatar>
                    <v-avatar class="status-icon" :color="item.online ? '#1FD445' : '#FF000C'" size="11">
                    </v-avatar>
                  </div>
                  <div class="user-dtl ml-4">
                    <h4 class="" :class="item.company_name === 'Admin User'
                        ? 'fw-medium content g-color chatRepNameAdmin'
                        : 'fw-medium content g-color chatRepName'
                      ">{{ item.company_name }}</h4>
                    <p class="heading text-dark">
                      {{ item.representative_name }}
                    </p>
                  </div>
                </div>
              </td>
              <td>
                <v-avatar v-if="item.notSeen" class="ml-4 showUserNotiCount" :color="'#A0DCA0'" size="26">
                  {{ item.notSeen }}</v-avatar>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div v-if="openChat" class="modal-fullscreen chatmodal-dialog inr-modal">
        <div class="modal-header pattern-green pattern-g-after">
          <div class="d-flex align-center"
            @click="openChat = false; dropChatScreen(); msg = ''; image = ''; unreadedMessages = true;">
            <img class="chat-icon-m" src="../../assets/images/arrow-left.svg" />
            <v-toolbar-title class="white--text ml-3">{{ currentUser.representative_name }}
            </v-toolbar-title>
          </div>
          <span v-if="typing" class="body-1 typing">
            <i>{{ $t("chat.typing") }}...</i>
          </span>
        </div>
        <div class="chat-container">
          <div class="chat-body">
            <div v-for="(item, index) in chat" :key="index" :class="[
              'd-flex flex-row align-center',
              item.from == currentUserData[0].id ? 'justify-end' : null,
            ]">
              <span v-if="item.from == currentUserData[0].id" class="ml-3 ml-sm-6 mb-3 chatting-msg own-chatting">
                <h6 class="chatDate">
                  {{ item.sent | DateTimezoneFilter }}
                </h6>
                <span class="chattext own-chattext">
                  <img style="max-width: 330px" class="cursorPointer" :src="item.image" height="140px"
                    v-if="item.image !== ''" @click="
                      imageSlider = true;
                    imgUrl = item.image;
                    " />
                  <div v-if="item.msg !== ''">{{ item.msg }}</div>
                </span>
                <v-avatar class="on-user float-right" size="24">
                  <span>
                    {{ currentUserData[0].representative_name[0] }}
                  </span>
                </v-avatar>
              </span>
              <div v-if="item.from == currentUser.id">
                <div v-if="unreadedMessages && item.unseen" class="separator">
                  {{ $t("chat.unreadMessages") }}
                </div>
                <div class="d-flex chatting-msg">
                  <div class="user-avtar">
                    <v-avatar class="user-avtar-icon rounded-circle" size="40">
                      <span>{{ currentUser.representative_name.split(' ').slice(0, 2)
                        .map(word => word.charAt(0).toUpperCase())
                        .join('') }}</span>
                    </v-avatar>
                    <v-avatar class="status-icon" :color="item.online ? '#1FD445' : '#FF000C'" size="11">
                    </v-avatar>
                  </div>
                  <div class="ml-2 ml-sm-4 mr-4 mr-sm-5">
                    <h6>{{ item.sent | DateTimezoneFilter }}</h6>
                    <span class="chattext">
                      <img style="max-width: 330px" class="cursorPointer" :src="item.image" height="140px"
                        v-if="item.image !== ''" @click="
                          imageSlider = true;
                        imgUrl = item.image;
                        " />
                      <div v-if="item.msg !== ''">{{ item.msg }}</div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="chat-footer d-flex justify-space-between">
            <input type="text" class="form-control" :placeholder="$t('chat.type_message')" id="chatInput" v-model="msg"
              @click="unreadedMessages = false" @keyup="userTyping(currentUser)" @keypress.enter="send(currentUser)">
            <button class="btn custom-btn pattern-btn send-btn" id="sendBtn" :loading="startUploadImage"
              @click="send(currentUser)">
              <span><img class="chat-icon-m" src="../../assets/images/send.svg" /></span>
            </button>
          </div>
        </div>
        <!-- <v-footer fixed>
          <v-container class="ma-0 pa-0">
            <v-row no-gutters>
              <v-col>
                <div class="d-flex flex-row align-center">
                  <v-textarea
                    type="text"
                    class="chattextarea"
                    append-icon="mdi-comment"
                    no-details
                    solo
                    rows="2"
                    hide-details
                    v-model="msg"
                    @click="unreadedMessages = false"
                    @keyup="userTyping(currentUser)"
                    placeholder="Type Something"
                    @keypress.enter="send(currentUser)"
                  ></v-textarea>
                  <input
                    type="file"
                    multiple
                    name="file"
                    @change="onChange"
                    id="assetsFieldHandle"
                    ref="file"
                    accept=".jpg,.jpeg,.png"
                  />
                  <label for="assetsFieldHandle" class="imageuploadChat"
                    ><v-icon for="assetsFieldHandle"
                      >mdi-file-image</v-icon
                    ></label
                  >
                  <v-btn
                    medium
                    light
                    :loading="startUploadImage"
                    min-width="10"
                    min-height="50"
                    class="green darken-4 green-gradient white--text ml-4"
                    elevation="0"
                    @click="send(currentUser)"
                    ><v-icon>mdi-send</v-icon></v-btn
                  >
                </div>
                <div class="uploadingImage" v-if="image !== ''">
                  <span class="carousel-close-icon" @click="closePreview()"
                    ><v-icon>mdi-close</v-icon></span
                  >
                  <img
                    class="cursorPointer"
                    height="235px"
                    :src="image"
                    v-if="image !== ''"
                  />
                </div>
              </v-col>
            </v-row>
            <emoji-picker @emoji="append" :search="search" style="margin: 1px">
              <div
                style="top: 0.25rem"
                class="emoji-invoker"
                slot="emoji-invoker"
                slot-scope="{ events: { click: clickEvent } }"
                @click.stop="clickEvent"
              >
                <svg
                  class="emojipicker-message"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path
                    d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"
                  />
                </svg>
              </div>
              <div slot="emoji-picker" slot-scope="{ emojis, insert }">
                <div class="emoji-picker message-emoji-picker-chat">
                  <div class="emoji-picker__search">
                    <input type="text" v-model="search" />
                  </div>
                  <div>
                    <div
                      v-for="(emojiGroup, category) in emojis"
                      :key="category"
                    >
                      <h5>{{ category }}</h5>
                      <div class="emojis">
                        <span
                          v-for="(emoji, emojiName) in emojiGroup"
                          :key="emojiName"
                          @click="insert(emoji)"
                          :title="emojiName"
                        >
                          {{ emoji }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </emoji-picker>
          </v-container>
        </v-footer> -->
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { debounce } from "lodash";
import io from "socket.io-client";
const socket = io.connect(process.env.VUE_APP_CHAT_URL);
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    show: {
      required: true,
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      showInput: false,
      searchMember: "",
      lastscrollvalue: null,
      image: "",
      startUploadImage: false,
      imgUrl: null,
      imageSlider: false,
      search: "",
      totalNotificationCount: 0,
      unreadedMessages: true,
      typing: false,
      showChat: this.show,
      msgOccurs: true,
      currentUserData: [],
      currentUser: null,
      onlineIds: [],
      openChat: false,
      chat: [],
      counter: 1,
      msg: "",
      items: [
        { title: "Home", icon: "mdi-home-city" },
        { title: "My Account", icon: "mdi-account" },
        { title: "Users", icon: "mdi-account-group-outline" },
      ],
      users: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      allUsers: "members/getUsers",
    }),
  },
  watch: {
    show(val) {
      this.searchMember = "";
      this.showChat = val;
      if (!this.showChat) {
        this.getUsers();
        this.msg = "";
        this.image = "";
      }
    },
    showChat(val) {
      if (!val) {
        this.unreadedMessages = true;
        this.openChat = false;
        this.dropChatScreen();
        this.$emit("updateShow", val);
      }
    },
  },

  mounted() {
    this.getUsers();
    this.callSocketListeners();
    this.setupUserEvents();
  },
  destroyed() {
    console.log(
      "================================================>Component Destroyes!!"
    );
    socket.emit("disconnect");
  },
  methods: {
    ...mapActions({
      getAllUsers: "members/getUsers",
    }),

    append(emoji) {
      this.msg += emoji;
    },

    filterMember: debounce(function () {
      this.getUsers();
    }, 100),

    stopTyping: debounce(function () {
      this.typing = false;
    }, 500),

    async getUsers() {
      try {
        await this.getAllUsers({
          search: this.searchMember,
        });
        const index = this.allUsers.findIndex(
          (item) => item.id == this.user.id
        );
        if (index !== -1) {
          this.currentUserData = this.allUsers.splice(index, 1);
          console.log("==========>", this.currentUserData[0]);
        }
        socket.emit("online", this.currentUserData[0]);
      } catch (error) {
        console.log(error);
      }
    },

    openSendMessage(id, name) {
      socket.emit("openChat", id, name);
    },

    userTyping(user) {
      socket.emit("userTyping", user.id);
    },

    scrollToBottom() {
      if (!this.openChat) {
        return false;
      }
      setTimeout(() => {
        let container = this.$el.querySelector(".v-navigation-drawer__content");
        container.scrollTop = container.scrollHeight;
      }, 10);
    },

    onChange() {
      this.startUploadImage = true;
      let data = this.$refs.file.files[0];
      let reader = new FileReader();
      const self = this;
      reader.onload = function (evt) {
        self.image = evt.target.result;
        self.startUploadImage = false;
        // console.log(this.image);
      };
      reader.readAsDataURL(data);
    },

    scrollToTop() {
      if (this.openChat) {
        return false;
      }
      setTimeout(() => {
        let container = this.$el.querySelector(".v-navigation-drawer__content");
        container.scrollTop = 0;
      }, 10);
    },

    send(user) {
      if (this.msg.trim() === "" && this.image === "") {
        return;
      }
      console.log(this.image);
      socket.emit(
        "sendMessage",
        user.id,
        user.representative_name,
        JSON.stringify({ msg: this.msg, image: this.image })
      );
      this.msg = "";
      this.image = "";
    },

    dropChatScreen() {
      this.scrollToTop();
      this.counter = 1;
      socket.emit("chatAway");
    },

    closePreview() {
      this.image = "";
    },

    isFeedAtTop() {
      let el = this.$el.querySelector(".v-navigation-drawer__content");
      return 0 === el.scrollTop;
    },

    setupUserEvents() {
      let a = this.$el.querySelector(".v-navigation-drawer__content");
      a.addEventListener("scroll", () => {
        if (this.openChat) {
          // document on which scroll event will occur
          if (this.lastscrollvalue == null) {
            this.lastscrollvalue = a.scrollTop;

            // sets lastscrollvalue
          } else if (a.scrollTop > this.lastscrollvalue) {
            // downscroll rules will be here
            this.lastscrollvalue = a.scrollTop;
          } else if (a.scrollTop < this.lastscrollvalue) {
            // upscroll rules will be here
            if (a.scrollTop >= 0 && a.scrollTop <= 500) {
              socket.emit("loadPrevMessage", this.currentUser.id, this.counter);
            }
            this.lastscrollvalue = a.scrollTop;
          }
        }
      });
    },

    callSocketListeners() {
      const self = this;
      // Socket Connection Listener
      socket.on("connect", function () {
        self.getUsers();
        console.log("Chat has connected to the server!");
      });

      socket.on("disconnect", function () {
        self.getUsers();
        console.log("Chat has disconnected to the server!");
      });

      // Get all Online Users connected to socket
      socket.on("allOnlineUsers", function (data) {
        self.onlineIds = data.map((val) => val.username);
        self.onlineIds.forEach((item) => {
          const index = self.allUsers.findIndex((val) => val.id == item);
          if (index !== -1) {
            self.allUsers[index].online = true;
          }
        });
        socket.emit("notifications");
        if (self.msgOccurs) {
          self.msgOccurs = false;
          self.msgOccurs = true;
        }
        self.dropChatScreen();
      });

      // Get Notification Count from Socket
      socket.on("notifications", function (data) {
        if (data.notifications && data.notifications.length) {
          self.totalNotificationCount = data.notifications
            .map((val) => val.notSeen)
            .reduce(function (a, b) {
              return a + b;
            }, 0);
          data.notifications
            .sort((a, b) => a.lastUpdate - b.lastUpdate)
            .forEach((item) => {
              const index = self.allUsers.findIndex(
                (val) => val.id == item.username
              );
              if (index !== -1) {
                self.allUsers[index].notSeen = item.notSeen;
                if (item.notSeen > 0) {
                  const latestNotificationUser = self.allUsers.splice(index, 1);
                  self.allUsers.unshift(latestNotificationUser[0]);
                }
              }
            });
          if (self.msgOccurs) {
            self.msgOccurs = false;
            self.msgOccurs = true;
          }

          self.$emit("countUnseen", self.totalNotificationCount);
        }
      });

      // Update User Status to Offline
      socket.on("userOffline", function (data) {
        const index = self.allUsers.findIndex((val) => val.id == data);
        if (index !== -1) {
          self.allUsers[index].online = false;
          if (self.msgOccurs) {
            self.msgOccurs = false;
            self.msgOccurs = true;
          }
        }
      });

      // call when message seen
      socket.on("messagesSeen", function (data) {
        console.log(data);
      });

      // Listen User Typing
      socket.on("userTyping", function () {
        self.typing = true;
        self.stopTyping();
      });

      // recieve message on chat screen
      socket.on("receiveMessage", function (data) {
        console.log(data);
        let chatData = {
          ...data,
          msg: JSON.parse(data.message).msg,
          image: JSON.parse(data.message).image,
        };
        self.chat.push(chatData);

        self.scrollToBottom();
      });

      // Get All message with selected user
      socket.on("allMessages", function (data) {
        console.log(data);
        const index = data.findIndex((val) => val.seen === false);
        self.chat = [];
        data.forEach((val) => {
          let chatData = {
            ...val,
            msg: JSON.parse(val.message).msg,
            image: JSON.parse(val.message).image,
          };
          self.chat.push(chatData);
        });

        if (index != -1) {
          self.chat[index].unseen = true;
        }

        self.scrollToBottom();
      });

      socket.on("loadPrevMessages", function (data) {
        self.counter++;
        console.log(this.counter);
        console.log(data);
        data.forEach((val) => {
          let chatData = {
            ...val,
            msg: JSON.parse(val.message).msg,
            image: JSON.parse(val.message).image,
          };
          self.chat.unshift(chatData);
        });
        let container = self.$el.querySelector(".v-navigation-drawer__content");
        container.scrollTop = container.scrollHeight / 3;

        // if (self.isFeedAtTop()) {
        //   self.scrollToBottom();
        // }
      });
    },
  },
};
</script>

<style scoped>
.v-dialog__content {
  position: absolute;
}
</style>
