import Echo from "laravel-echo";

window.io = require("socket.io-client");
window.Echo = new Echo({
  broadcaster: "socket.io",
  host:
    process.env.VUE_APP_MIX_API_BASE_URL +
    ":" +
    process.env.VUE_APP_MIX_ECHO_SERVER_PORT,
  auth: {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    },
  },
});
